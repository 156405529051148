export const generateEventSchema = (event) => ({
    "@context": "https://schema.org",
    "@type": "Event",
    "name": event.event_title,
    "description": event.items_offered,
    "date": `${event.date.split('/').join('-')}`,
    "location": {
        "@type": "Place",
        "name": event.venue_name,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": event.address,
            "addressLocality": "New York",
            "addressRegion": "NY",
            "addressCountry": "US"
        }
    },
    "organizer": {
        "@type": "Organization",
        "name": event.venue_name
    }
});

export const generateWebsiteSchema = () => ({
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "POPOUT.NYC",
    "description": "Discover pop-up events across NYC - food, art, music, and more",
    "url": "https://popout.nyc"
});
